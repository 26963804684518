// $(function () {
//   $('a[href^="#"]').click(function () {
//     var speed = 400;
//     var href = $(this).attr("href");
//     var target = $(href == "#" || href == "" ? "html" : href);
//     var position = target.offset().top;
//     $("body,html").animate({ scrollTop: position }, speed, "swing");
//     return false;
//   });
// });
$(function () {
	var topBtn = $("#page-top");
	topBtn.hide();
	$(window).scroll(function () {
		if ($(this).scrollTop() > 300) {
			topBtn.fadeIn();
		} else {
			topBtn.fadeOut();
		}
	});
});

const heroHeight = 0;

$(".anime__text-char").each(function () {
	$(this)
		.children()
		.addBack()
		.contents()
		.each(function () {
			if (this.nodeType == 3) {
				$(this).replaceWith(
					$(this).text().replace(/(\S)/g, "<span>$1</span>")
				);
			}
		});

	$(this).on("inview", function () {
		$(this).css({ opacity: 1 });
		for (var i = 0; i <= $(this).children("span").length; i++) {
			$(this)
				.children("span")
				.eq(i)
				.delay(60 * i)
				.animate({ opacity: 1 }, 1200);
		}
	});
});

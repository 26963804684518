import "jquery-drawer";

$(".drawer").drawer({
  iscroll: {
    mouseWheel: false,
  },
});

// var $win = $(window);

// $win.on("load resize", function () {
//   if (window.matchMedia("(max-width:1000px)").matches) {
//     $(".drawer-hover-content").hide();
//     $(".drawer-hover").on("click", function () {
//       $(this).children("span").toggleClass("open");
//       $(this).children("ul").css("padding-top", "10px").slideToggle();
//     });
//   } else {
//     $(".drawer-hover").hover(
//       function () {
//         $(this).find(".drawer-hover-content").addClass("dHover");
//       },
//       function () {
//         $(this).find(".drawer-hover-content").removeClass("dHover");
//       }
//     );
//   }
// });

jQuery(function ($) {
  var windowWidth = window.innerWidth;
  if (windowWidth <= 1000) {
    $(".drawer-hover").on("click", function () {
      $(this).find(".drawer-plus").toggleClass("open");
      $(this)
        .find(".drawer-hover-content")
        .css("padding-top", "10px")
        .slideToggle();
    });
  } else {
    $(".drawer-hover").hover(
      function () {
        $(this).find(".drawer-hover-content").addClass("dHover");
      },
      function () {
        $(this).find(".drawer-hover-content").removeClass("dHover");
      }
    );
  }
});
var pageURL = location.pathname;
var pageURLArr = pageURL.split("/");
var pageURLArrRoot = pageURLArr[2];
var pageURLArrCategory = pageURLArr[3];
console.log(pageURLArrCategory);

// $(".drawer-link").each(function (i, v) {
//   var selfhref = $(v).attr("href");
//   var hrefArr = selfhref.split("/");
//   console.log(hrefArr);
//   var hrefArrCategory = hrefArr[2];

//   if (pageURLArrCategory === hrefArrCategory) {
//     $(v).addClass("current");
//   }
// });

if (
  pageURLArrCategory === "company" ||
  pageURLArrCategory === "history" ||
  pageURLArrCategory === "office" ||
  pageURLArrCategory === "environment" ||
  pageURLArrCategory === "items" ||
  pageURLArrCategory === "info"

) {
  $(".info").addClass("drawer-link current");
} else if (pageURLArrRoot === "info") {
  $(".info").addClass("drawer-link current");
}
if (
  pageURLArrCategory === "cardboard" ||
  pageURLArrCategory === "anti-covid" ||
  pageURLArrCategory === "polyethylene" ||
  pageURLArrCategory === "steel-pallet" ||
  pageURLArrCategory === "product"

) {
  $(".product").addClass("drawer-link current");
} else if (pageURLArrRoot === "product") {
  $(".product").addClass("drawer-link current");
}

/*global jQuery */
/*!
 * Lettering.JS 0.6.1
 *
 * Copyright 2010, Dave Rupert http://daverupert.com
 * Released under the WTFPL license
 * http://sam.zoy.org/wtfpl/
 *
 * Thanks to Paul Irish - http://paulirish.com - for the feedback.
 *
 * Date: Mon Sep 20 17:14:00 2010 -0600
 */
(function ($) {
  function injector(t, splitter, klass, after) {
    var a = t.text().split(splitter),
      inject = "";
    if (a.length) {
      $(a).each(function (i, item) {
        inject +=
          '<span class="' + klass + (i + 1) + '">' + item + "</span>" + after;
      });
      t.empty().append(inject);
    }
  }

  var methods = {
    init: function () {
      return this.each(function () {
        injector($(this), "", "char", "");
      });
    },

    words: function () {
      return this.each(function () {
        injector($(this), " ", "word", " ");
      });
    },

    lines: function () {
      return this.each(function () {
        var r = "eefec303079ad17405c889e092e105b0";
        // Because it's hard to split a <br/> tag consistently across browsers,
        // (*ahem* IE *ahem*), we replaces all <br/> instances with an md5 hash
        // (of the word "split").  If you're trying to use this plugin on that
        // md5 hash string, it will fail because you're being ridiculous.
        injector($(this).children("br").replaceWith(r).end(), r, "line", "");
      });
    },
  };

  $.fn.lettering = function (method) {
    // Method calling logic
    if (method && methods[method]) {
      return methods[method].apply(this, [].slice.call(arguments, 1));
    } else if (method === "letters" || !method) {
      return methods.init.apply(this, [].slice.call(arguments, 0)); // always pass an array
    }
    $.error("Method " + method + " does not exist on jQuery.lettering");
    return this;
  };
})(jQuery);

var $win = $(window);

var windowWidth = window.innerWidth;

if (windowWidth > 576) {
  $(".anime__txt").on("inview", function (event, isInview) {
    $(this)
      .css("opacity", 1)
      .textillate({
        initialDelay: 1500,
        in: {
          effect: "fadeInLeft",
          delay: 15,
          sync: false,
          shuffle: false,
          reverse: false,
          callback: function () {},
        },
      });
  });
  $(".anime__txt02").on("inview", function (event, isInview) {
    $(this)
      .css("opacity", 1)
      .textillate({
        initialDelay: 1250,
        in: {
          effect: "fadeInLeft",
          delay: 15,
          sync: false,
          shuffle: false,
          reverse: false,
          callback: function () {},
        },
      });
  });
} else {
  $(".anime__txt").on("inview", function (event, isInview) {
    $(this)
      .css("opacity", 1)
      .textillate({
        initialDelay: 750,
        in: {
          effect: "fadeInLeft",
          delay: 15,
          sync: false,
          shuffle: false,
          reverse: false,
          callback: function () {},
        },
      });
  });
  $(".anime__txt02").on("inview", function (event, isInview) {
    $(this)
      .css("opacity", 1)
      .textillate({
        initialDelay: 1000,
        in: {
          effect: "fadeInLeft",
          delay: 15,
          sync: false,
          shuffle: false,
          reverse: false,
          callback: function () {},
        },
      });
  });
}

$(".anime__txt03").on("inview", function (event, isInview) {
  $(this)
    .css("opacity", 1)
    .textillate({
      initialDelay: 250,
      in: {
        effect: "fadeInLeft",
        delay: 15,
        sync: false,
        shuffle: false,
        reverse: false,
        callback: function () {},
      },
    });
});

import ScrollHint from 'scroll-hint';

window.addEventListener("DOMContentLoaded", function () {
  new ScrollHint(".table-responsive", {
    scrollHintIconAppendClass: "scroll-hint-icon-white", // white-icon will appear
    i18n: {
      scrollable: "スクロールできます",
    },
  });
});

import Vivus from "vivus";
import ScrollReveal from "scrollreveal";

// var els = document.getElementsByClassName("mhl");

// $(els).on("inview", function () {
//   for (var i = els.length - 1; i >= 0; i--) {
//     new Vivus(els[i], {
//       duration: 20,
//       type: "oneByOne",
//       start: "inViewport",
//       animTimingFunction: Vivus.EASE_OUT,
//     });
//   }
// });
function addLine(){
}
ScrollReveal().reveal(".anime__text-reveal", {
  delay:200,
  duration: 1000,
  distance: "15px",
  origin: "bottom",
  afterReveal:addLine
});

ScrollReveal().reveal(".anime__text-reveal-right", {
  delay: 200,
  duration: 1000,
  distance: "15px",
  origin: "right",
  afterReveal:addLine
});

ScrollReveal().reveal(".anime__text-reveal-left", {
  delay: 200,
  duration: 1000,
  distance: "15px",
  origin: "left",
  afterReveal:addLine
});

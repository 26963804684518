import Swiper from "swiper/bundle";
import SwiperCore, { Navigation, Pagination, Scrollbar } from "swiper/core";
SwiperCore.use([Navigation, Pagination, Scrollbar]);

var getTimeout = (function () {
	var e = setTimeout,
		b = {};
	setTimeout = function (a, c) {
		var d = e(a, c);
		b[d] = [Date.now(), c];
		return d;
	};
	return function (a) {
		return (a = b[a]) ? Math.max(a[1] - Date.now() + a[0], 0) : NaN;
	};
})();

function sanitisePercentage(i) {
	return Math.min(100, Math.max(0, i));
}

var percentTime;
var tick;
var progressBar = document.querySelector(".swiper-hero-progress");

var mySwiper = new Swiper(".swiper-hero", {
	effect: "fade",
	loop: true,
	speed: 1000,
	slidesPerView: 1,
	// spaceBetween: 30,
	// grabCursor: true,
	// keyboard: {
	//   enabled: true,
	//   onlyInViewport: true,
	// },
	// watchOverflow: true,
	watchSlidesProgress: true,
	watchSlidesVisibility: true,
	// roundLengths: true,
	autoplay: {
		delay: 4000,
		disableOnInteraction: false,
	},
	pagination: {
		el: ".swiper-pagination",
		type: "fraction",
		renderFraction: function (currentClass, totalClass) {
			return (
				'<span class="' +
				currentClass +
				'"></span>' +
				'<span class="' +
				totalClass +
				'"></span>'
			);
		},
	},
	on: {
		slideChange: function () {
			var swiper = this;
			var defaultSlideDelay = swiper.params.autoplay.delay;
			var currentIndex = swiper.realIndex + 1;
			var currentSlide = swiper.slides[currentIndex];
			var currentSlideDelay =
				currentSlide.getAttribute("data-swiper-autoplay") ||
				defaultSlideDelay;

			updateSwiperProgressBar(progressBar, currentSlideDelay);
		},
	},
});

function updateSwiperProgressBar(bar, slideDelay) {
	function startProgressBar() {
		resetProgressBar();
		tick = setInterval(progress, 50);
	}

	function progress() {
		var timeLeft = getTimeout(mySwiper.autoplay.timeout);

		if (mySwiper.autoplay.running && !mySwiper.autoplay.paused) {
			percentTime = sanitisePercentage(
				100 - Math.round((timeLeft / slideDelay) * 100)
			);
			bar.style.width = percentTime + "%";

			if (percentTime > 100) {
				resetProgressBar();
			}
		}

		if (mySwiper.autoplay.paused) {
			percentTime = 0;
			bar.style.width = 0;
		}
	}

	function resetProgressBar() {
		percentTime = 0;
		bar.style.width = 0;
		clearInterval(tick);
	}

	startProgressBar();
}

const heroHeight = $(".hero").height() - 88;

$(window).scroll(function () {
	var top = $(window).scrollTop();
	if (heroHeight < top) {
		$(".drawer-nav").addClass("heroHeight");
		$("#drawer-sp__wrapper").addClass("drawer-sp__wrapper");
		$(".drawer-hamburger").addClass("underHero");
	} else {
		$(".drawer-nav").removeClass("heroHeight");
		$("#drawer-sp__wrapper").removeClass("drawer-sp__wrapper");
		$(".drawer-hamburger").removeClass("underHero");
	}
});

if ($(".hero-child").length) {
	var heroHeightChild = $(".hero-child").height() - 88;

	$(window).scroll(function () {
		var topChild = $(window).scrollTop();
		if (heroHeightChild < topChild) {
			$(".drawer-nav").addClass("heroHeight");
		} else {
			$(".drawer-nav").removeClass("heroHeight");
		}
	});
}

// var cardHeight = $('#cardHeight').height() * 1.3;
// $('#cardShrink').css("height", cardHeight);

//Recruit Top Swiper
var recSwiper = new Swiper(".swiper-works", {
	autoplay: {
		delay: 5000,
	},
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	grabCursor: true,
	loop: true,
	pagination: {
		el: ".swiper-pagination",
		type: "bullets",
	},
});
